// App colors
$color-primary-light: #00929E;
$color-primary-dark: #193340;
$color-secondary: #FF5F4D;
$color-white: #f8fafc;
$bg-color: $color-primary-light;
$bg-light: $color-white;
$bg-dark: $color-primary-dark;
$text-light: $color-white;
$text-color-primary-dark: $color-primary-dark;
$text-color-primary-light: $color-primary-light;
$text-color-secondary: $color-secondary;
$text-link: $color-secondary;


// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

.text-pink{
    color: $pink !important;
}

.text-orange{
    color: $orange !important;
}

.text-red{
    color: $red !important;
}

.text-purple{
    color: $purple !important;
}

.text-green{
    color: $green !important;
}

.text-blue{
    color: $blue !important;
}
